h2,
h3,
h1 {
  color: #f87d6f;
}

p {
  margin-bottom: 0;
}

.grey-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 31, 0.6);
}
